import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import ContactForm from '../components/contactForm'

import '../styles/pages/_site-survey.scss'

const SiteSurveyPage = ({ data: { datoCmsOurOceanPage: page } }) => (
  <Layout>
    <Helmet>
      <title>Free Site Survey</title>
      {/* eslint-disable-next-line */}
      <script type="text/javascript">var form_id = 'NOCHET70LQ';</script>
      <script src="//app8.campus-site.com/assets/application/js/formgen.js" async defer />
    </Helmet>
    <section
      className="hero is-large is-page-hero"
      style={{
        backgroundImage: `url(${page.heroBackgroundImage.fixed.src})`
      }}
    >
      <div className="hero-body">
        <div className="container">
          <h1 className="title has-text-white has-text-weight-normal">
            site survey
          </h1>
        </div>
      </div>
    </section>
    <section className="hero">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title">Book your free site survey</h1>
          <p>
            Fill out the details below to claim your free site survey, and to
            find out how we can help you.
          </p>
        </div>
      </div>
    </section>
    <section className="section" style={{ marginBottom: '6rem' }}>
      <div className="columns is-centered">
        <div className="column is-half">
          <ContactForm />
        </div>
      </div>
    </section>
  </Layout>
)

SiteSurveyPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default SiteSurveyPage

export const query = graphql`
  query SiteSurveyQuery {
    datoCmsOurOceanPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroBackgroundImage {
        fixed(
          width: 1920
          imgixParams: {
            auto: "compress"
            fm: "pjpg"
            crop: "entropy"
            bri: -15
          }
        ) {
          src
        }
      }
    }
  }
`
